import Image from "next/image";
import { CloseContainer } from "../ModalHeader/styled";

export default function ModalClose({ onRequestClose, white }) {
  return (
    <div>
      <CloseContainer
        onKeyPress={(e) =>
          e.key === "Enter" && onRequestClose && onRequestClose()
        }
        onClick={() => {
          onRequestClose && onRequestClose();
        }}
      >
        <Image
          src={
            white
              ? "/icons/collapse_close_white.png"
              : "/icons/collapse_close_purple.png"
          }
          width="42"
          height="42"
          layout="fixed"
          alt="close modal"
          tabIndex="0"
        />
      </CloseContainer>
    </div>
  );
}
