export const sendPixelInfo = async (data) => {
  try {
    const response = await fetch("/api/crm/fb-conversion", {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({ ...data, "event_time": new Date().toUTCString() })
    })
    let res = await response.json();
    return res;
  } catch (error) {
    return error;
  }
}