import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendContactRequestAction } from "../../../redux/actions/send-contact.actions";
import { eventFormSubmission } from "../../../services/analytics/ga4CustomEvents";
import { Form } from "antd";
import { withTheme } from "styled-components";
import FormConsultationSmall from "./small";
import FormConsultationLarge from "./large";
import { setGoogleAdwordsInfo } from "../../../utils/helper";
import { getQueryFromStorage } from "../../../hooks/useQueryCatcher";
import { sendPixelInfo } from "../../../utils/sendPixel";

const FormConsultation = ({ theme, variant, referralCode }) => {
  const { loading, failed } = useSelector(
    ({ sentContactData }) => sentContactData,
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (values) => {
    let url = typeof window !== "undefined" ? window.location.href : "null";
    setGoogleAdwordsInfo(values);
    dispatch(
      sendContactRequestAction({ ...values, queries: getQueryFromStorage() }),
    );
    eventFormSubmission(url, "lead", {
      email: values.email,
      phone: values.phone,
    });
    sendPixelInfo(values)
      .then(res => {
        console.log({ res });
      })
      .catch(err => {
        console.error({ err });
      })
    setTimeout(() => {
      form.resetFields();
      setShowModal(true);
    }, 400);
  };

  if (variant === "large")
    return (
      <FormConsultationLarge
        handleSubmit={handleSubmit}
        loading={loading}
        form={form}
        theme={theme}
        referralCode={referralCode}
        failed={failed}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    );

  return (
    <FormConsultationSmall
      handleSubmit={handleSubmit}
      loading={loading}
      form={form}
      theme={theme}
      referralCode={referralCode}
      failed={failed}
      showModal={showModal}
      setShowModal={setShowModal}
    />
  );
};

export default withTheme(FormConsultation);
