import Image from "next/image";
import { Header, CloseContainer } from "./styled";

export default function ModalHeader({ onRequestClose, children }) {
  return (
    <Header>
      <CloseContainer onKeyPress={(e) => e.key === "Enter" && onRequestClose && onRequestClose()} onClick={() => { onRequestClose && onRequestClose() }}>
        <Image
          src="/icons/collapse_close_white.png"
          width="42"
          height="42"
          layout="fixed"
          alt="close modal"
          tabIndex="0"
        />
      </CloseContainer>
      {children}
    </Header>
  );
}
