import Modal from "../../Modal";
import Typography from "../../../components/Typography";
import Image from "next/image";
import { Container, Content, ImageContainer } from "./styled";
import useWindowDimentions from "../../../hooks/useWindowDimensions";

export default function ModalContactSuccess({ visible, onRequestClose }) {
  const { isSmallDevice } = useWindowDimentions();
  return (
    <Modal
      visible={visible}
      hideBorder={true}
      style={{
        backgroundColor: "transparent",
        borderWidth: "0px",
      }}
      maxWidth={740}
    >
      <Modal.Body>
        <Modal.Close onRequestClose={onRequestClose} />
        <Container>
          <Content>
            <Typography
              textAlign="center"
              color="darkGray2"
              fontSize={isSmallDevice ? "1.8rem" : "2.5rem"}
              fontWeight="bold"
              fontType="header"
            >
              Thank you for contacting us!
            </Typography>

            <Typography
              textAlign="center"
              fontSize={isSmallDevice ? "1rem" : "1.5rem"}
            >
              We look forward to putting a smile on your face by designing a
              perfect customized storage solution tailored especially for you!
            </Typography>
            <ImageContainer>
              <Image
                src="/images/contact/contact-success.jpg"
                width="800"
                height="424"
                alt="Contact Success"
                layout="responsive"
              />
            </ImageContainer>
          </Content>
          <Typography
            textAlign="center"
            fontSize="0.85rem"
            color="borderColor1"
            margin={[10, 0, 0, 0]}
          >
            We’ll be contacting you to discuss your needs and confirm a time
            that is convenient for you to have your Personal Design Professional
            meet with you for a FREE, in-home or in-office Consultation.
          </Typography>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
