import cookie from "cookie";

export function parseCookies(req) {
  return cookie.parse(req ? req.headers.cookie || "" : document.cookie);
}

export function setGoogleAdwordsInfo(values) {
  if (window.globalEmail && window.globalPhone) return;
  window.globalEmail = values.email;
  window.globalPhone = `1${values.phone}`;
  return
}
