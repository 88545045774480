import Modal from "./ModalLayout";
import ModalHeader from "./ModalHeader";
import ModalBody from "./ModalBody";
import ModalClose from "./ModalClose";

export default Object.assign(Modal, {
  Header: ModalHeader,
  Body: ModalBody,
  Close: ModalClose,
});
