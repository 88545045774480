export default {
  // ==================================================================================
  // This part is for mapping meta data object key names of the webpage name.
  // So you can use this object key name for passing it as the props of the meta component
  // when you render meta component in the every webpage
  // ==================================================================================
  dataKeyMaps: {
    HOME: "home",
    WHY_CHOOSE_CS: "whyChooseCS",
    GET_IN_TOUCH: "getInTouch",
    OUR_STORY: "ourStory",
    FAQ: "faq",
    PRODUCT_CLOSETS: "closets",
    FRANCHISE_OPPORTUNITIES: "franchiseOpp",
    CABINET_DOORS: "cabinetDoors",
    PRIVACY_POLICY: "privacyPolicy",
    VIEW_ALL: "viewAll",
    LOCATIONS: "locations",
    LOCATION_CHANDLER_ARIZONA: "chandlerArizona",
    BLOG: "blog",
    FINANCING: "financing"
  },
  // ==================================================================================
  // It is meta data objects for the webpage
  // ==================================================================================
  home: {
    title: "Custom Closets, Cabinetry & Storage Solutions - Classy Closets of SLC",
    description:
      "",
    canonical: "https://closetsutah.com/",
    robots:
      "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  },
  locations: {
    title: "Our Locations - Classy Closets",
    description:
      "",
    canonical: "https://closetsutah.com/view-all-products",
    robots:
      "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  },
  franchiseOpp: {
    title: "Franchise Opportunities - Classy Closets",
    description:
      "Classy Closets has been providing its customers with high quality, custom closets and an array of other custom storage solutions for over 35 years.",
    canonical: "https://closetsutah.com/franchise-opportunities",
    robots:
      "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  },
  privacyPolicy: {
    title: "Privacy Policy - Classy Closets",
    description:
      "Classy Closets has been providing its customers with high quality, custom closets and an array of other custom storage solutions for over 35 years.",
    canonical: "https://closetsutah.com/privacy-policy",
    robots:
      "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  },
  viewAll: {
    title: "Our Products - Classy Closets of SLC",
    description:
      "Classy Closets has been providing its customers with high quality, custom closets and an array of other custom storage solutions for over 35 years.",
    canonical: "https://closetsutah.com/view-all-products",
    robots:
      "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  },
  whyChooseCS: {
    title: "Why Choose Us - Classy Closets | The Classy Closets Difference",
    description:
      "Why Choose Classy Closets: Discover Exceptional Custom Storage Solutions, Expert Craftsmanship, and Personalized Service",
    canonical: "https://closetsutah.com/why-choose-classy-closets",
    robots:
      "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  },
  getInTouch: {
    title: "Contact Us - Classy Closets of SLC",
    description:
      "Contact Classy Closets: Get in Touch for Custom Design Consultations, Expert Storage Solutions, and Outstanding Service",
    canonical: "https://closetsutah.com/get-in-touch",
    robots:
      "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  },
  ourStory: {
    title: "Our Story - Classy Closets of SLC",
    description:
      "Classy Closets has been improving the lives of families and businesses through well-organized closet storage solutions designed for optimal living. More...",
    canonical: "https://closetsutah.com/our-story",
    robots:
      "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  },
  faq: {
    title: "FAQs - Classy Closets of SLC",
    description:
      "FAQ: Your Questions Answered - Custom Design Process, Product Options, and Expert Storage Solutions",
    canonical: "https://closetsutah.com/frequently-asked-questions",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  blog: {
    title: "Blog - Classy Closets of SLC - Life. Organized.",
    description:
      "Classy Closets Blog - Classy Closets - Helping You Keep Life. Organized.",
    canonical: "https://closetsutah.com/blogs/posts",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  careers: {
    title: "Career Opportunities - Classy Closets",
    description:
      "Classy Closets jobs, careers, and opportunities",
    canonical: "https://closetsutah.com/careers",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  // ==================================================================================
  // This part is the products pages group
  // ==================================================================================
  "closets": {
    title: "Closets - Custom Closets by Classy Closets of SLC",
    description:
      "Explore stunning custom closet designs by Classy Closets - tailored storage solutions, personalized style, and expert craftsmanship to transform your space. Browse our closet options now!",
    canonical: "https://closetsutah.com/products/closets",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "wall-beds": {
    title: "Wall Beds - Murphy Beds - Classy Closets",
    description: "Wall Beds by Classy Closets: Space-Saving Custom Designs, Expert Craftsmanship & Stylish Multifunctional Solutions",
    canonical: "https://closetsutah.com/products/wall-beds",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "pantry": {
    title: "Pantry Storage - Custom Pantry Design",
    description: "Maximize your kitchen efficiency with Classy Closets' custom pantry storage solutions - innovative designs, personalized organization, and quality craftsmanship. Explore our pantry options today!",
    canonical: "https://closetsutah.com/products/pantry",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "home-offices": {
    title: "Home Offices - Custom Workspaces",
    description: "Elevate your work-from-home experience with Classy Closets' custom home offices - personalized designs, optimized functionality, and exceptional craftsmanship. Discover your dream workspace now!",
    canonical: "https://closetsutah.com/products/home-offices",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "media-centers": {
    title: "Media Centers - Media Walls",
    description: "Custom Media Centers by Classy Closets: Seamless Integration, Stylish Designs & Expert Craftsmanship for Enhanced Entertainment",
    canonical: "https://closetsutah.com/products/media-centers",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "libraries": {
    title: "Libraries & Bookshelves - Classy Closets",
    description: "Custom Libraries by Classy Closets: Elegant Designs, Expert Craftsmanship & Personalized Spaces to Showcase Your Book Collection",
    canonical: "https://closetsutah.com/products/libraries",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "laundry-rooms": {
    title: "Laundry Rooms - Classy Closets",
    description: "Laundry Room Solutions by Classy Closets: Custom Cabinetry, Functional Designs & Expert Craftsmanship for an Organized Space",
    canonical: "https://closetsutah.com/products/laundry-rooms",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "garage-cabinets": {
    title: "Garage Cabinets - Classy Closets",
    description: "Garage Cabinets by Classy Closets: Custom Storage Solutions, Expert Craftsmanship & Organized Garage Transformations",
    canonical: "https://closetsutah.com/products/garage-cabinets",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  cabinetDoors: {
    title: "Cabinet Doors - Classy Closets",
    description: "",
    canonical: "https://closetsutah.com/cabinet-doors",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "commercial-offices": {
    title: "Commercial Offices - Classy Closets",
    description: "At Classy Closets, we feel that there is no office too small or too big that couldn't benefit from one of our designs!",
    canonical: "https://closetsutah.com/products/commercial-offices",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "kitchen-bath": {
    title: "Kitchen & Bath - Classy Closets",
    description: "At Classy Closets, we feel that there is no kitchen too small or too big that couldn't benefit from one of our designs!",
    canonical: "https://closetsutah.com/products/kitchen-bath",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "materials-and-finishes": {
    title: "Materials & Finishes - Classy Closets",
    description: "At Classy Closets, we feel that there is no kitchen too small or too big that couldn't benefit from one of our designs!",
    canonical: "https://closetsutah.com/products/materials-and-finsihes",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "luxury-closets": {
    title: "Luxury Closets by Classy Closets: Custom Designs, Elegance & Expert Craftsmanship",
    description: "Indulge in Classy Closets' luxury custom closets - elegant designs, premium materials, and unparalleled craftsmanship for a sophisticated and organized wardrobe. Discover our luxurious options now!",
    canonical: "https://closetsutah.com/products/luxury-closets",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "kids-closets": {
    title: "Kid's Closets: Custom Storage Designed for Kids",
    description: "Kid's Custom Closets by Classy Closets: Fun, Functional & Personalized Storage Solutions for Your Child's Space",
    canonical: "https://closetsutah.com/products/kids-closets",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "walk-in-closets": {
    title: "Walk-In Closets - Classy Closets",
    description: "Custom Walk-In Closets by Classy Closets: Personalized Designs, Expert Craftsmanship & Luxurious Organization Solutions",
    canonical: "https://closetsutah.com/products/walk-in-closets",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "reach-in-closets": {
    title: "Reach-In Closets - Custom Closet Solutions",
    description: "Optimize your storage with Classy Closets' custom reach-in closets - tailored designs, smart organization, and superior craftsmanship. Discover your perfect reach-in closet solution today!",
    canonical: "https://closetsutah.com/products/reach-in-closets",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  // ==================================================================================
  // This part is the individual location pages group
  // ==================================================================================



  saltLakeCity: {
    title: "Salt Lake City Closets & Custom Cabinetry - Classy Closets",
    description: "Discover Classy Closets of Salt Lake City - custom storage solutions, expert design, and local craftsmanship for closets, home offices, and more. Visit our showroom today!",
    canonical: "https://closetsutah.com/locations/salt-lake-city",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  // ==================================================================================
  // Accessibility
  // ==================================================================================
  accessibility: {
    title: "Accessibility statement - Classy Closets",
    description: "Classy Closets is committed to making our website's content accessible and user friendly to everyone",
    canonical: "https://closetsutah.com/accessibility-statement",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  materialsAndFinishes: {
    title: "Materials and finishes - Classy Closets",
    description: "Classy Closets has plenty of options for your materials and finishes needs",
    canonical: "https://closetsutah.com/materials-and-finishes",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "fbLanding": {
    title: "Closets - Custom Closets by Classy Closets",
    description:
      "At Classy Closets, we feel that there is no closet too small or too big that couldn't benefit from one of our designs!",
    canonical: "https://closetsutah.com/landing/social",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "financing": {
    title: "Check Out Our Financing Options",
    description:
      "",
    canonical: "https://closetsutah.com/landing/financing",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  holiday: {
    title: "Classy Holiday Sale Event - Classy Closets",
    description: "Classy Closets is having a holiday sale event! Get 15% off all wood grain melamine!",
    canonical: "https://closetsutah.com/holiday",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  }
};
