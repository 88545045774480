import styled from "styled-components";

export const Header = styled.div`
  background-color: #762738;
  position: relative;
  padding: 10px 60px;
`;

export const CloseContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
`;
