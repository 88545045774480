export function getCSSExternalSpace(space) {
  if (typeof space === "string") {
    return space;
  } else if (typeof space === "number") {
    return space + "px";
  } else if (Array.isArray(space)) {
    return space.join("px ") + "px";
  }
  return "";
}
