import styled from "styled-components";

const ModalBody = styled.div`
  min-height: 500px;
  padding: 10px;

  @media only screen and (max-width: 768px) {
    overflow-y: auto;
    padding: 20px;
  }
`;

export default ModalBody;
