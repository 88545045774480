import styled from "styled-components";

export const Container = styled.div`
  max-width: calc(590px + 150px);
  margin: 0px auto;
  padding: 0px calc(150px / 2);

  @media only screen and (max-width: 768px) {
    padding: 0px calc(60px / 2);
  }
`;

export const ImageContainer = styled.div`
  margin: 0px calc(-210px / 2);
  @media only screen and (max-width: 768px) {
    margin: 0px calc(-140px / 2);
  }
`;

export const Content = styled.div`
  padding: 0px 20px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: calc(100%);
    height: calc(100% - 20px);
    top: 0px;
    left: 0px;
    border: 3px solid #762738;
    z-index: 1;
  }
`;
