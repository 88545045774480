import { useEffect, useState } from "react";
import { FormContainer, FormItem, StyledInput, PButton, StyledTextArea } from "./styled";
import { Row, Col } from "antd";
import Loading from "../../../components/Loading";
import ModalContactSuccess from "../../Modals/ModalContactSuccess";

export default function FormConsultationSmall({
  handleSubmit,
  form,
  theme,
  loading,
  referralCode,
  showModal,
  setShowModal
}) {
  const [urlParams, setUrlParams] = useState(null);
  useEffect(() => {
    if (typeof window !== "undefined") {
      const queryString = window?.location?.search;
      const urlParams = new URLSearchParams(queryString);
      if (queryString) {
        let obj = {};
        const entries = urlParams.entries();
        for (const entry of entries) {
          obj[entry[0]] = entry[1];
        }
        setUrlParams(obj);
      }
    }

    return () => {
      setUrlParams(null);
    };
  }, []);

  return (
    <div>
      <ModalContactSuccess
        visible={showModal}
        onRequestClose={() => setShowModal(false)}
      />
      <FormContainer name="small-form" size="small" onFinish={(values) => handleSubmit({ ...values, ...urlParams })} form={form}>
        <FormItem
          validateTrigger={"onSubmit"}
          name={"first_name"}
          rules={[
            {
              required: true,
              message: "First name required",
            },
          ]}
        >
          <StyledInput placeholder={"First Name"} />
        </FormItem>
        <FormItem
          validateTrigger={"onSubmit"}
          name={"last_name"}
          rules={[
            {
              required: true,
              message: "Last name required",
            },
          ]}
        >
          <StyledInput placeholder={"Last Name"} />
        </FormItem>
        <FormItem
          name={"email"}
          rules={[
            {
              required: true,
              message: "Email required",
            },
            {
              type: "email",
              message: "Not a valid email",
            },
          ]}
        >
          <StyledInput placeholder={"Email address"} />
        </FormItem>
        <FormItem
          name={"phone"}
          rules={[
            {
              required: true,
              message: "Phone required",
            },
            { pattern: /^\d+$/, message: "Enter only digits" },
          ]}
        >
          <StyledInput placeholder={"Phone Number"} />
        </FormItem>
        <Row gutter={4}>
          <Col span={14}>
            <FormItem
              validateTrigger={"onSubmit"}
              name={"city"}
              rules={[
                {
                  required: false,
                  message: "City required",
                },
              ]}
            >
              <StyledInput placeholder={"City"} />
            </FormItem>
          </Col>
          <Col span={10}>
            <FormItem
              name={"state"}
              rules={[
                {
                  required: false,
                  message: "State required",
                },
              ]}
            >
              <StyledInput placeholder={"State"} />
            </FormItem>
          </Col>
        </Row>
        <FormItem
          name={"message"}
          rules={[
            {
              required: false,
              message: "Comments",
            },
          ]}
        >
          <StyledTextArea
            placeholder={"Comments"}
            rows={3}
          />
        </FormItem>
        <FormItem initialValue={referralCode} name={"referral_code"}>
          <StyledInput
            placeholder={"Promotional Code"}
            primary={true}
          />
        </FormItem>
        <FormItem>
          <PButton
            data-cy="submit"
            ptype="primary"
            pname="GET STARTED"
            width="100%"
            bgColor={theme.colors.prune}
            hoverBgColor={theme.colors.hoverColor2}
            height="20px"
            shadow="yes"
            htmlType="submit"
          />
        </FormItem>
      </FormContainer>
      <Loading loading={loading} />
    </div>
  );
}
